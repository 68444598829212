<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <cms-component-text comp-id="text" :component="block.text" v-bind="componentProps"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-text #hot-reload-debug
export default {
    name: `cms-block-text`,
    components: {CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock
}
</script>

<style lang="scss" scoped>
</style>
